import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    dark: false,
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: { // A
          base: '#920683',
          darken1: '#820373', // R
          lighten1: '#F2C9EE' // C
        },
        primaryBtnText: { // A'
          base: '#FFFFFF'
        },
        secondary: { // B
          base: '#BFAE72',
          darken1: '#3D2E00' // D
        },
        secondaryBtnText: { // B'
          base: '#FFFFFF'
        },
        headerBack: { // E
          base: '#920683'
        },
        headerText: { // F
          base: '#FFFFFF'
        },
        logoutBtnText: { // G
          base: '#FFFFFF'
        },
        topSchoolNamePc: { // H
          base: '#920683'
        },
        topSecondaryBtn: { // I
          base: '#BFAE72'
        },
        topSecondaryBtnText: { // J
          base: '#FFFFFF'
        },
        topPrimaryBtn: { // K
          base: '#920683'
        },
        topPrimaryBtnText: { // L
          base: '#FFFFFF'
        },
        topBtnUpText: { // M
          base: '#FFFFFF'
        },
        primaryOutLineBtnText: { // N
          base: '#920683'
        },
        secondaryOutLineBtnText: { // O
          base: '#920683'
        },
        topBtnUpTextSp: { // P
          base: '#920683'
        },
        topBtnBackSp: { // Q
          base: '#FFFFFF'
        },
        eventHeaderBack: {
          base: '#FFB6FF'
        },
        eventHeaderText: {
          base: '#FFFFFF'
        },
        eventTopBorderSub: {
          base: '#BFAE72'
        },
        accent: '#920683', // datepickerなどの色
        formBackground: '#F6f6f6'
      },
      dark: {
        primary: '#920683',
        secondary: '#BFAE72',
        accent: '#920683',
        formBackground: '#F6f6f6'
      }
    }
  }
})
